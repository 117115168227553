import React, { useEffect } from 'react';
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';
import Logo from '../images/version2/LabsWhite.png'
import Banner from '../images/midyearupdate/Banner.png'
import TeqitCloud from '../images/midyearupdate/TeqitCloud.gif'
import impact1 from '../images/midyearupdate/impact1.png'
import impact2 from '../images/midyearupdate/impact2.jpeg'
import impact3 from '../images/midyearupdate/impact3.jpeg'
import impact4 from '../images/midyearupdate/impact4.jpeg'
import impact5 from '../images/midyearupdate/impact5.jpeg'
// import FooterBanner from '../images/midyearupdate/Footer.png'
import FooterBg from '../images/midyearupdate/FooterBg.png'
import Config from '../helper/Config';
import { Link } from "react-router-dom";

export default function MidYearUpdate2024(props) {
    let { bannerState } = props;

    useEffect(() => {
        analyticsUserTrack("MidYearUpdate2024", "Landing");
    }, [])

    return <div className='version-2'>
        <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
            <div className={"home-sticky-crop"} style={{ top: 0 }}>
                <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                    <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                </Link>
                <Header page="MidYearUpdate2024" />
            </div>
        </div>
        <div style={{ marginTop: 100 }} className='main-container '>
            <div className='mid-year-banner text-center'>
                <img className='w-100' src={Banner} />
            </div>
            <div className='container mid-year my-5' style={{ fontSize: "1.25em" }}>
                <div>
                    <b>Date of Issue: August 5, 2024</b>
                </div>
                <br />
                <div style={{ border: "1px solid #9fea41" }} />
                <h3>A Letter From The Chief Catalyst</h3>
                <h3>"It is Time"</h3>
                <div className='py-3' >
                    <h4 style={{ color: "#2f8c9f", fontWeight: "bold" }}>By Vidya Sri</h4>
                </div>
                <div>
                    As we advance the work, we have been investing time and resources in the markets we serve to get a deeper understanding of the problems we can help solve. This has led us through 32 countries and 15 collaborations in 3 years with the carbon sensitive <a href='https://teqit.me/'>Teqit</a> communications platform. Teqit is a major milestone in our 25 year journey which began in 1999.
                </div>

                <h3>What have we learned?</h3>
                <ol>
                    <li>
                        In the highly connected world we live in, there is an absence of meaningful connectivity.
                        <ul style={{ listStyle: "square"}}>
                            <li>Bots make up half of internet traffic globally with bad (malicious) bots at a third of this total. Bots will outnumber humans on the internet shortly. This reduces the quality of incoming data and the opportunity to deliver a consistent customer experience.</li>
                            <li>People tend to scan and skip online content rather than reading text word for word. This deepens gaps in understanding and sustains communication gaps over time. This helps increase incentives for distrust and disinformation.</li>
                        </ul>
                    </li>
                    <li>
                        In a world where many can access AI, computing resources (hardware, storage, networking, software, the cloud) are still resource heavy and too many people cannot access them.
                        <ul style={{ listStyle: "square"}}>
                            <li>This greatly reduces the ability to innovate at scale and makes it difficult to create efficiencies between human resources and data resources.</li>
                        </ul>
                    </li>
                    <li>
                        It is time to close the gap between aspiration and execution.
                    </li>
                </ol>

                <h3>Introducing the Teqit Cloud</h3>
                <div className='d-flex justify-content-center'>
                    <div style={{ maxWidth: 500 }}>
                        <img src={TeqitCloud} className='img-fluid' />
                    </div>
                </div>
                <ol>
                    <li>The Teqit Cloud uses a Zero Trust environment to keep the bad bots out. No one is trusted. Everyone is verified. Data quality is protected. The customer experience is optimized. It is time.</li>
                    <li>The Teqit Cloud delivers essential data with Enhanced Communication. Targeted workspaces integrate data with video meetings, instant messaging, continual learning analytics, app platforms and more (in 70 languages and counting). No logging in and out of multiple services to widen communication gaps and leave trails for bad bots to follow. It is time.</li>
                    <li>The Teqit Cloud makes innovation affordable at scale. Increasing productivity through the  reduced cost of technology. Getting the job done without breaking the bank. It is time.</li>
                </ol>

                <h3>What Is The Impact? </h3>
                <div style={{ border: "1px solid #9fea41" }} />
                <div className='d-flex how-impact py-5'>
                    <div className='number px-2 text-center'>1</div>
                    <div className='image px-2'><img src={impact1} className='img-fluid' /></div>
                    <div className='content'>
                        <b>Enhanced Communication for Better Patient Care</b>
                        <div className='mt-3'>
                            By collaborating with leading innovators in Electronic Health Records (EHRs), Teqit makes it easier and faster for doctors to access patient data from a wide range of EHRs. It is time to address the global shortage of doctors by reducing the physician burn out that 71% of doctors say EHRs greatly contribute to (Stanford Medicine) and reduce the burden of time required by EHRs (by 50% with Teqit).
                            We cannot get serious about improving access to health without acknowledging the need to improve the physician experience.
                        </div>
                    </div>
                </div>
                <div className='d-flex how-impact py-5'>
                    <div className='number px-2 text-center'>2</div>
                    <div className='image px-2'><img src={impact2} className='img-fluid' /></div>
                    <div className='content'>
                        <b>Meaningful Connectivity Favored for Continuity of Care</b>
                        <div className='mt-3'>
                            Led by Ground Breaking Organizations Embajadores De Jesus,  La Iglesia Fronteriza, and Border Line Crisis Center, public health workers co-created a personal health app on Teqit to help manage continuity of care in Mexico.
                            It is time to address crucial information gaps that prevent doctors from prescribing treatment for 280 million people in the transit context because this also helps to reduce the potential for fast moving pandemics to spread around the world.
                        </div>
                    </div>
                </div>
                <div className='d-flex how-impact py-5'>
                    <div className='number px-2 text-center'>3</div>
                    <div className='image px-2'><img src={impact3} className='img-fluid' /></div>
                    <div className='content'>
                        <b>Teqit as a resource for more than 1 in 3 women worldwide</b>
                        <div className='mt-3'>
                            Led by Influential Community Advocate and Mobilizer, Bose Ironsi, Teqbahn and Women's Rights and Health Project (WRAHP), co-created and launched the Encare Response on Teqit.
                            It is time to make it easier and safer to report gender based violence, an issue that affects more than 1 in 3 women worldwide with measurable negative economic consequences for the individual, the family, the community, the country and the world.
                        </div>
                    </div>
                </div>
                <div className='d-flex how-impact py-5'>
                    <div className='number px-2 text-center'>4</div>
                    <div className='image px-2'><img src={impact4} className='img-fluid' /></div>
                    <div className='content'>
                        <b>Capacity Building through Enhanced Communication</b>
                        <div className='mt-3'>
                            In collaboration with Digital Rights Advocate Gladys Mbuyah, Teqbahn met with African Women's Development Fund in Accra to discuss how grantees can reduce precious time spent on reporting from 8 hours to 30 minutes with Teqit.
                            It is time to create economies of scale for the global grantee community
                            by making Teqit available to the 1 million organizations actively receiving grants from a diverse range of funders with specific reporting requirements.
                        </div>
                    </div>
                </div>
                <div className='d-flex how-impact py-5'>
                    <div className='number px-2 text-center'>5</div>
                    <div className='image px-2'><img src={impact5} className='img-fluid' /></div>
                    <div className='content'>
                        <b>Equitable Access to Health - East Africa</b>
                        <div className='mt-3'>
                            Inspired by a Call to Action from senior neurosurgeons Moody Qureshi, M.D. and Paul H Young, M.D.,  Teqit was used to launch a global working group in Nairobi to help address access to neurosurgery in East Africa. Africa has the fewest neurosurgeons globally with an estimated one neurosurgeon for every 4 million people. This model has applications across medical specialties and geographic regions due to the global shortage of doctors.
                            It is time to close life threatening gaps in access to health through the reduced cost of technology.
                        </div>
                    </div>
                </div>

                <h3>Scaling the Triple Bottom Line is Next</h3>
                <div className='my-2'>
                    We believe that all companies can drive social well-being, environmental health and a strong economy: people, planet, prosperity. We do this at Teqbahn by improving productivity through the greatly reduced cost of technology.
                </div>
                <div className='my-2'>
                    We believe that technology is like the air we breathe. Critical and powerful but light and invisible.
                </div>

                <h3>The Struggle to Survive</h3>
                <div className='my-2'>
                    We are closing gaps in digital literacy and infrastructure to improve the adoption of e-commerce for Local Micro, Small, and Medium Enterprises (MSMEs) in Sri Lanka.
                </div>

                <h3>Why?</h3>
                <div className='my-2'>
                    <ul style={{ listStyle: "circle"}}>
                        <li>To deepen the digital footprint for MSMEs and make their businesses more sustainable.</li>
                        <li>To develop a scalable model for the digital needs of 71 million MSMEs in Southeast Asia who face considerable barriers in the effective adoption of e-commerce.</li>
                    </ul>
                </div>
                <div className='my-2'>
                    It is time to reduce the struggle for survival of local businesses everywhere.
                </div>
                <h3>The Struggle to Thrive</h3>
                <div className='my-2'>
                    We are scaling AI supported - chat and voice based customer support with a 70% reduction in per user cost.
                </div>
                <h3>Why?</h3>
                <div className='my-2'>
                    <ul style={{ listStyle: "circle"}}>
                        <li>So MSMEs can have affordable customer support to increase customer loyalty and drive revenue.</li>
                        <li>To address a common point of business failure, often due to a basic lack of resources.</li>
                    </ul>
                </div>
                <div className='my-2'>
                    The market applications for the Teqit Cloud are numerous. This is just the beginning.
                </div>
                <div style={{ border: "1px solid #9fea41" }} />

                <h3>It is Time</h3>
                <div className='my-2'>
                    At Teqbahn, we want to play a leading role in scaling the triple bottom line to help drive sustainability: People, Planet, Prosperity.
                </div>
                <div className='my-2'>
                    Information Technology continues to produce more carbon than all of aviation combined (the Shift Project). We are forging a more sustainable digital landscape to help tackle some of the greatest problems of our time.
                </div>
                <div className='my-2'>
                    By pioneering edge-to-cloud communication platforms that are not just innovative but also carbon-sensitive, we are reflecting our commitment to technological advancement, environmental stewardship, and social well-being.
                </div>
                <h3>To Go To Market</h3>
                <div className='my-2'>
                    We invite resource partners, funders and investors to go to market with us. It is time to make affordable innovation more available at scale and we are ready. Are you?
                </div>
                <div className='my-2'>
                    It is time to work together for more meaningful connectivity.
                </div>
            </div>
            <div className='mid-year-footer-banner' style={{ backgroundImage: `url(${FooterBg})`}}>
                <div style={{ padding: 50}}>
                    <b>Thank you for your role in changing the world together</b>
                    <div className='mx-3 my-3'>-The Teqbahn Global Community</div>
                </div>
                <div className='text-center'>
                    <a href='https://teqbahn.com/'>https://teqbahn.com/</a>
                    <div className='mx-3 my-3'>
                        To share your thoughts and for interest in partnering, please write to us at <a href="mailto:changetheworldtogether@teqbahn.com">changetheworldtogether@teqbahn.com</a>
                    </div>
                </div>
                <div className='text-center address-text' >
                    Teqbahn is registered as Teqbahn Foundation <br/>
                    A 501(c)(3) organization. Tax ID: 86-3034752 <br/>
                    244 Fifth Avenue Suite 2724, New York N.Y. 10001 <br/>
                    email: <a href="mailto:contact@teqbahn.com">contact@teqbahn.com</a>
                </div>
            </div>
        </div>
    </div>;
}
