import React from 'react';
import Header from './component/Header';
import Logo from '../images/version2/LabsWhite.png'
import { Style } from "react-style-tag";
import O_photoCropped from '../images/O_photoCropped.jpg';
import Carousel from '../components/CarouselCsit';
import { analyticsUserTrack } from '../config/analyticsTrack';
import MyConstant from '../config/MyConstant';
import { Link } from 'react-router-dom';
import Config from '../helper/Config';

class CSITV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            deviceWidth: 0,
            imgWidth: 0,

        }
    }
    componentDidMount() {
        analyticsUserTrack("CSIT", "Landing");
    }
    render() {
        let { bannerState } = this.props;
        return (
            <div>
                <Style>
                    {`
                .header {
                    padding: 10px;
                    color: #f1f1f1;
                    position: fixed;
                }
                .sticky {
                    padding: 10px;
                    top: 0;
                    z-index:2;
                   /* background-image: url(${O_photoCropped});*/
                    background-size: cover;
                    background-repeat: no-repeat;
                  }
                  .list-text{
                    margin-Left: 5rem;
                    padding-Right:5rem;
                    font-Size:22px;
                    list-style-type: number;
                    width: auto;
                    font-family: Helvetica-Regular;
                  }
                  .list-text-sub{
                    margin-Left: 6rem;
                    padding-Right:5rem;
                    font-Size:22px;
                    list-style-type: none;  
                    width: auto;
                    font-family: Helvetica-Regular;
                    color : #000
                  }
                  .ulist-text{
                    font-Size:22px;
                    margin-Left:0
                    font-family: Helvetica-Regular;
                    color : #000
                  }
                 a.tpr-link{
                  }
                  a.tpr-link:hover{
                    color:#272626
                  }
               `}
                </Style>
                <div className='version-2'>
                    <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
                        <div className={"home-sticky-crop"} style={{ top: 0 }}>
                            <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                                <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                            </Link>
                            <Header page="CSIT" />
                        </div>
                    </div>
                </div>
                <div style={{ paddingTop: bannerState ? (window.screen.width < 768 ? 200 : 140) : 100 }} className='tpr_home_body main-container'>
                    <div className='main-header'>
                        <h1>
                            CSIT
                        </h1>
                    </div>
                    <div className='py-5 container' style={{ lineHeight: "34px"}}>
                        <ul className={`ulist-text`}>
                        <Link to={MyConstant.keyList.projectUrl+"/tprhome"}>
                        Carbon Sensitive Tech based on TPR
                        </Link>
                            <li className={`list-text`}>Low server resources compared to existing technology paradigms - cloud services, on prem traditional tech.</li>
                            <li className={`list-text`}>Zero post data flow ingestion analytics - so called big data analytics.</li>
                            <li className={`list-text`}>Continual learning system of vectorized data trees with weights.</li>
                            <li className={`list-text`}>Judicious use of quantized LLMs with CPUs.</li>
                            <li className={`list-text-sub`}>1. So called Function calling - we call it Message Passing to automate workflows.</li>
                        </ul>
                    </div>
                    <Carousel />
                </div>
            </div>
        )
    }
}

export default CSITV2;